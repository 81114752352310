<template>
  <div class="pageContol formCom">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">结算管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">结算列表</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">详情</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <h3 class="flexcb doedit" style="padding-bottom: 20px">
              <span>班级基本信息</span>
            </h3>
            <div class="form-detail">
              <el-form :inline="true" ref="ruleForm" label-width="12rem">
                <el-form-item label="班级名称：" class="form-inline">
                  <span>{{ ruleForm.projectName || "--" }}</span>
                </el-form-item>
                <el-form-item label="班级编码：" class="form-inline">
                  <span>{{ ruleForm.projectCode || "--" }}</span>
                </el-form-item>
                <el-form-item label="机构名称：" class="form-inline">
                  <span>{{ ruleForm.compName || "--" }}</span>
                </el-form-item>
                <el-form-item label="行政区划：" class="form-inline">
                  <span>{{ ruleForm.areaName || "--" }}</span>
                </el-form-item>
                <el-form-item label="培训类型：" class="form-inline">
                  <span>{{ ruleForm.trainTypeName || "--" }}</span>
                </el-form-item>
                <el-form-item label="岗位类型：" class="form-inline">
                  <span>{{ ruleForm.postName || "--" }}</span>
                </el-form-item>
                <el-form-item label="行业类型：" class="form-inline">
                  <span>{{ ruleForm.industryName || "--" }}</span>
                </el-form-item>
                <el-form-item label="职业/工种：" class="form-inline">
                  <span>{{ ruleForm.occupationName || "--" }}</span>
                </el-form-item>
                <el-form-item label="级别：" class="form-inline">
                  <span>{{ ruleForm.trainLevelName || "--" }}</span>
                </el-form-item>
                <el-form-item label="班级总课时：" class="form-inline">
                  <span>{{ ruleForm.totalLessonNum || "--" }}</span>
                </el-form-item>
                <el-form-item label="公共总课时：" class="form-inline">
                  <span>{{ ruleForm.publicLessonNum || "--" }}</span>
                </el-form-item>
                <el-form-item label="开班时间：" class="form-inline">
                  <span>{{ ruleForm.startDate || "--" }}</span>
                </el-form-item>
                <el-form-item label="结束时间：" class="form-inline">
                  <span>{{ ruleForm.endDate || "--" }}</span>
                </el-form-item>
                <el-form-item label="计划培训人数：" class="form-inline">
                  <span>{{ ruleForm.projectPeople || "--" }}</span>
                </el-form-item>
                <el-form-item label="班级学习人数：" class="form-inline">
                  <span>{{ ruleForm.studyNum || "--" }}</span>
                </el-form-item>
                <el-form-item label="班级完成人数：" class="form-inline">
                  <span>{{ ruleForm.completeNum || "--" }}</span>
                </el-form-item>
                <el-form-item label="班级结业人数：" class="form-inline">
                  <span>{{ ruleForm.completeNumAll || "--" }}</span>
                </el-form-item>
                <el-form-item label="认证人数：" class="form-inline">
                  <span>{{ ruleForm.authenticNum || "--" }}</span>
                </el-form-item>
                <el-form-item label="国家补贴标准：" class="form-inline">
                  <span>{{ ruleForm.countrySubsidyStandard || "--" }}</span>
                </el-form-item>
                <el-form-item label="补贴类别：" class="form-inline">
                  <span>{{ ruleForm.subsidyLevel || "--" }}</span>
                </el-form-item>
              </el-form>
              <template v-if="ruleForm.courseProviderList">
                <el-form
                  v-for="(item, index) in ruleForm.courseProviderList"
                  :key="index"
                  :inline="true"
                  ref="ruleForm"
                  label-width="12rem"
                >
                  <el-form-item
                    :label="'课程提供方' + toChinesNum(index + 1) + '：'"
                    class="form-inline"
                  >
                    <span>{{ item.compName || "" }}</span>
                  </el-form-item>
                  <el-form-item
                    :label="'课程提供方' + toChinesNum(index + 1) + '学时：'"
                    class="form-inline"
                  >
                    <span>{{ item.lessionNum || "" }}学时</span>
                  </el-form-item>
                </el-form>
              </template>
            </div>
            <h3 class="flexcb doedit" style="padding-bottom: 20px">
              <span>收款信息</span>
            </h3>
            <div class="form-detail">
              <!-- <el-form :inline="true" ref="ruleForm" label-width="12rem">
                <el-form-item label="收款结算单号：" class="form-inline">
                  <span>{{ ruleForm.billNo || "--" }}</span>
                </el-form-item>
                <el-form-item label="收款结算单状态：" class="form-inline">
                  <span>{{ ruleForm.billState || "--" }}</span>
                </el-form-item>
                <el-form-item label="收款结算是否已到账：" class="form-inline">
                  <span>{{ ruleForm.arrival ? "是" : "否" }}</span>
                </el-form-item>
                <el-form-item label="收款结算人数：" class="form-inline">
                  <span>{{ ruleForm.settleNum || "--" }}</span>
                </el-form-item>
                <el-form-item label="应收平台技术服务费：" class="form-inline">
                  <span>{{ ruleForm.platformTechnicalFee || "--" }}</span>
                </el-form-item>
                <el-form-item label="应收课程服务费：" class="form-inline">
                  <span>{{ ruleForm.courseFee || "--" }}</span>
                </el-form-item>
                <el-form-item label="应收平台服务费：" class="form-inline">
                  <span>{{ ruleForm.platformTotalFee || "--" }}</span>
                </el-form-item>
                <el-form-item label="开票情况：" class="form-inline">
                  <span>
                    {{ ruleForm.isInvoice ? "已开票" : "未开票" || "--" }}
                  </span>
                </el-form-item>
                <el-form-item label="收款结算单结款金额：" class="form-inline">
                  <span>{{ ruleForm.payerAmount || "--" }}</span>
                </el-form-item>
                <el-form-item label="收款日期：" class="form-inline">
                  <span>{{ ruleForm.payeeDate | momentDate}} </span>
                </el-form-item>
              </el-form> -->
              <el-table
                :data="ruleForm.paymentBillGatheringList"
                size="small"
                tooltip-effect="dark"
                style="width: 100%"
                :header-cell-style="tableHeader"
                stripe
              >
                <el-table-column
                  label="序号"
                  type="index"
                  show-overflow-tooltip
                  width="50px"
                />
                <el-table-column
                  label="结算单号"
                  align="left"
                  show-overflow-tooltip
                  prop="billNo"
                  min-width="150px"
                />
                <el-table-column
                  label="结算日期"
                  align="left"
                  show-overflow-tooltip
                  prop="billRecycleDate"
                  min-width="150px"
                >
                  <template slot-scope="scope">
                    {{ scope.row.billRecycleDate | momentDate }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="结算单状态"
                  align="left"
                  show-overflow-tooltip
                  prop="auditType"
                  min-width="150px"
                >
                  <template slot-scope="scope">
                    {{
                      $setDictionary(
                        "FD_AUDIT_TYPE",
                        scope.row.auditType
                      )
                    }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="结算单是否到账"
                  align="left"
                  show-overflow-tooltip
                  prop="arrival"
                  min-width="150px"
                />
                <el-table-column
                  label="结算人数"
                  align="left"
                  show-overflow-tooltip
                  prop="settleNum"
                />
                <el-table-column
                  label="应收平台技术服务费"
                  align="left"
                  show-overflow-tooltip
                  prop="platformTechnicalFee"
                  min-width="150px"
                />
                <el-table-column
                  label="应收课程服务费"
                  align="left"
                  show-overflow-tooltip
                  prop="courseFee"
                  min-width="150px"
                />
                <el-table-column
                  label="应收平台服务费"
                  align="left"
                  show-overflow-tooltip
                  prop="platformTotalFee"
                  min-width="150px"
                />
                <el-table-column
                  label="折后金额"
                  align="left"
                  show-overflow-tooltip
                  prop="settlementAmount"
                  min-width="150px"
                />
                <el-table-column
                  label="二次结算金额"
                  align="left"
                  show-overflow-tooltip
                  prop="secondFee"
                  min-width="150px"
                />
                <el-table-column
                  label="开票情况"
                  align="left"
                  show-overflow-tooltip
                  prop="isInvoice"
                >
                  <template slot-scope="scope">
                    {{ scope.row.isInvoice == "1" ? "是" : "否" }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="结款金额"
                  align="left"
                  show-overflow-tooltip
                  prop="actualReceivedFee"
                />
                <el-table-column
                  label="收款日期"
                  align="left"
                  show-overflow-tooltip
                  min-width="100"
                  prop="payeeDate"
                >
                  <template slot-scope="scope">
                    {{ scope.row.payeeDate | momentDate }}
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <h3
              class="flexcb doedit"
              style="padding-bottom: 20px"
              v-if="ruleForm.paymentBillList"
            >
              <span>付款信息</span>
            </h3>
            <div class="form-detail" v-if="ruleForm.paymentBillList">
              <!-- <el-form
                :inline="true"
                ref="ruleForm"
                label-width="12rem"
                v-for="(item, index) in ruleForm.paymentBillList"
                :key="index"
              >
                <el-form-item
                  :label="'课程提供方' + toChinesNum(index + 1) + '：'"
                  class="form-inline"
                >
                  <span>{{ item.compName }}</span>
                </el-form-item>
                <el-form-item
                  :label="'应付课程方' + toChinesNum(index + 1) + '课程费：'"
                  class="form-inline"
                >
                  <span>{{ item.courseMoney }}</span>
                </el-form-item>
                <el-form-item
                  :label="'课程方' + toChinesNum(index + 1) + '付款结算单号：'"
                  class="form-inline"
                >
                  <span>{{ item.billNo }}</span>
                </el-form-item>
                <el-form-item
                  :label="'课程方' + toChinesNum(index + 1) + '付款时间：'"
                  class="form-inline"
                >
                  <span>{{ item.payeeDate }}</span>
                </el-form-item>
                <el-form-item
                  :label="'是否收到课程方' + toChinesNum(index + 1) + '发票：'"
                  class="form-inline"
                >
                  <span>{{ item.isInvoice ? "是" : "否" }}</span>
                </el-form-item>
              </el-form> -->
              <el-table
                :data="ruleForm.paymentBillList"
                size="small"
                tooltip-effect="dark"
                style="width: 100%"
                :header-cell-style="tableHeader"
                stripe
              >
                <el-table-column
                  label="序号"
                  type="index"
                  show-overflow-tooltip
                  width="50px"
                />
                <el-table-column
                  label="结算单号"
                  align="left"
                  show-overflow-tooltip
                  prop="billNo"
                  min-width="150px"
                />
                <el-table-column
                  label="结算日期"
                  align="left"
                  show-overflow-tooltip
                  prop="billRecycleDate"
                  min-width="150px"
                >
                  <template slot-scope="scope">
                    {{ scope.row.billRecycleDate | momentDate }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="课程提供方"
                  align="left"
                  show-overflow-tooltip
                  prop="compName"
                  min-width="150px"
                />
                <el-table-column
                  label="课程费"
                  align="left"
                  show-overflow-tooltip
                  prop="courseMoney"
                  min-width="150px"
                />
                <el-table-column
                  label="付款时间"
                  align="left"
                  show-overflow-tooltip
                  prop="payeeDate"
                />
                <el-table-column
                  label="是否收到发票"
                  align="left"
                  show-overflow-tooltip
                  prop="isInvoice"
                  min-width="150px"
                >
                  <template slot-scope="scope">
                    {{ scope.row.isInvoice == "1" ? "是" : "否" }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="收款结算单号"
                  align="left"
                  show-overflow-tooltip
                  prop="receiptBillNo"
                  min-width="150px"
                />
              </el-table>
            </div>
            <h3
              class="flexcb doedit"
              style="padding: 20px 0"
              v-if="ruleForm.agentDetailBO"
            >
              <span>代理结算</span>
            </h3>
            <div class="form-detail" v-if="ruleForm.agentDetailBO">
<!--              <el-form :inline="true" ref="ruleForm" label-width="12rem">-->
<!--                <el-form-item label="代理结算单号：" class="form-inline">-->
<!--                  <span>{{ ruleForm.agentDetailBO.billNo || "&#45;&#45;" }}</span>-->
<!--                </el-form-item>-->
<!--                <el-form-item label="结算单状态：" class="form-inline">-->
<!--                  <span>{{-->
<!--                    $setDictionary(-->
<!--                      "FD_AUDIT_TYPE",-->
<!--                      ruleForm.agentDetailBO.auditType-->
<!--                    ) == "已到账"-->
<!--                      ? "已付款"-->
<!--                      : $setDictionary(-->
<!--                          "FD_AUDIT_TYPE",-->
<!--                          ruleForm.agentDetailBO.auditType-->
<!--                        )-->
<!--                  }}</span>-->
<!--                </el-form-item>-->
<!--                <el-form-item label="代理商：" class="form-inline">-->
<!--                  <span>{{ ruleForm.agentDetailBO.compName || "" }}</span>-->
<!--                </el-form-item>-->
<!--                <el-form-item label="付款比例：" class="form-inline">-->
<!--                  <span>{{-->
<!--                    ruleForm.agentDetailBO.agencyProportionStr || "&#45;&#45;"-->
<!--                  }}</span>-->
<!--                </el-form-item>-->
<!--                <el-form-item label="付款金额：" class="form-inline">-->
<!--                  <span>{{ ruleForm.agentDetailBO.agencyFee || "&#45;&#45;" }}</span>-->
<!--                </el-form-item>-->
<!--              </el-form>-->
              <el-table
                  :data="ruleForm.agentDetailBO"
                  size="small"
                  tooltip-effect="dark"
                  style="width: 100%"
                  :header-cell-style="tableHeader"
                  stripe
              >
                <el-table-column
                    label="序号"
                    type="index"
                    show-overflow-tooltip
                    width="50px"
                />
                <el-table-column
                    label="代理结算单号"
                    align="left"
                    show-overflow-tooltip
                    prop="billNo"
                    min-width="150px"
                >
                  <template slot-scope="scope">
                    {{ scope.row.billNo || "--" }}
                  </template>
                </el-table-column>
                <el-table-column
                    label="结算单状态"
                    align="left"
                    show-overflow-tooltip
                    prop="auditType"
                    min-width="150px"
                >
                  <template slot-scope="scope">
                    {{
                      $setDictionary(
                         "FD_AUDIT_TYPE",
                          scope.row.auditType
                          ) == "已到账"
                      ? "已付款"
                      : $setDictionary(
                          "FD_AUDIT_TYPE",
                          scope.row.auditType
                          )
                    }}
                  </template>
                </el-table-column>
                <el-table-column
                    label="代理商"
                    align="left"
                    show-overflow-tooltip
                    prop="compName"
                    min-width="150px"
                >
                  <template slot-scope="scope">
                    {{ scope.row.compName || "--" }}
                  </template>
                </el-table-column>
                <el-table-column
                    label="付款比例"
                    align="left"
                    show-overflow-tooltip
                    prop="agencyProportionStr"
                    min-width="150px"
                >
                  <template slot-scope="scope">
                    {{ scope.row.agencyProportionStr || "--" }}
                  </template>
                </el-table-column>
                <el-table-column
                    label="付款金额"
                    align="left"
                    show-overflow-tooltip
                    prop="agencyFee"
                    min-width="150px"
                >
                  <template slot-scope="scope">
                    {{ scope.row.agencyFee || "--" }}
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <h3 class="flexcb doedit" style="padding: 20px 0">
              <span>备注信息</span>
            </h3>
            <div>
              <el-table
                :data="ruleForm.tableList"
                size="small"
                tooltip-effect="dark"
                style="width: 100%"
                :header-cell-style="tableHeader"
                stripe
              >
                <el-table-column
                  label="备注内容"
                  align="left"
                  show-overflow-tooltip
                  prop="remark"
                  min-width="150px"
                />
                <el-table-column
                  label="备注人"
                  align="left"
                  show-overflow-tooltip
                  prop="fullname"
                  min-width="150px"
                />
                <el-table-column
                  label="备注时间"
                  align="left"
                  show-overflow-tooltip
                  prop="createTime"
                  min-width="150px"
                />
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "settlementDetails",
  components: {},
  data() {
    return {
      tableHeader: {
        background: "#5C6BE8",
        color: "#fff",
        borderColor: "#5C6BE8",
      },
      projectId: this.$route.query.projectId,
      ruleForm: {},
      ruleForm2: {},
    };
  },
  created() {},
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getDetail();
    },
    toChinesNum(num) {
      let changeNum = [
        "零",
        "一",
        "二",
        "三",
        "四",
        "五",
        "六",
        "七",
        "八",
        "九",
      ];
      let unit = ["", "十", "百", "千", "万"];
      num = parseInt(num);
      let getWan = (temp) => {
        let strArr = temp
          .toString()
          .split("")
          .reverse();
        let newNum = "";
        for (var i = 0; i < strArr.length; i++) {
          newNum =
            (i == 0 && strArr[i] == 0
              ? ""
              : i > 0 && strArr[i] == 0 && strArr[i - 1] == 0
              ? ""
              : changeNum[strArr[i]] + (strArr[i] == 0 ? unit[0] : unit[i])) +
            newNum;
        }
        return newNum;
      };
      let overWan = Math.floor(num / 10000);
      let noWan = num % 10000;
      if (noWan.toString().length < 4) {
        noWan = "0" + noWan;
      }
      return overWan ? getWan(overWan) + "万" + getWan(noWan) : getWan(num);
    },

    getDetail() {
      this.$post("/biz/new/bill/project/details", { projectId: this.projectId })
        .then((ret) => {
          this.ruleForm = {
            ...ret.data,
            courseProviderList: ret.data.courseProviderList,
            paymentBillList: ret.data.paymentBillList,
            tableList: ret.data.remarkList,
          };
        })
        .catch((err) => {
          return;
        });
    },
  },
};
</script>
<style lang="less">
.formCom {
  .el-form-item {
    margin-bottom: 5px;
  }
  .form-inline {
    width: 32%;
    .el-form-item__content {
      // width: 100%;
      span {
        width: 11rem;
      }
    }
  }
  .chapterl-btns {
    .el-button {
      padding: 8px;
    }
  }
  .el-cascader {
    width: 100%;
  }
  .el-progress {
    width: 200px;
  }
  .chapterl-trees {
    padding: 15px 15px 15px 0;
    .el-tree-node__content {
      height: 36px;
    }
    .draggable-item {
      margin-left: 5px;
      em {
        margin-right: 5px;
      }
    }
    .trees-btns {
      .showbtn {
        color: #fff;
      }
      margin-left: 15px;
      & > em,
      .handle > em {
        font-size: 14px;
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }
  .chapteru-lists {
    padding: 15px 15px 15px 0;
    .el-divider--horizontal {
      margin: 5px;
    }
    .cl-subhint {
      display: flex;
      span {
        font-size: 14px;
        color: #666;
        &:nth-child(2) {
          margin-left: 15px;
        }
      }
      em {
        margin: 0 5px;
      }
    }
    .cl-btns {
      i {
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }
  .cl-img-box {
    .el-icon-receiving {
      font-size: 40px;
      color: #999;
      margin-right: 15px;
    }
  }
  .pageForm-btns {
    padding: 15px 0;
    text-align: center;
    .el-button {
      span {
        min-width: 4em;
      }
    }
  }
}
.img-el-upload {
  padding: 20px 0;
  min-height: 120px;
}
.detail {
  .ellipsis {
    display: inline-block;
    width: 30rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 39px;
  }
}
.doedit {
  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #6676ff;
  }
}
</style>
<style lang="less" scope>
.el-textarea {
  .el-textarea__inner {
    min-height: 10px !important;
    height: 100px !important;
    resize: none;
  }
}
.courseIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
  background: url("../../assets/courseIcon.png") no-repeat;
  background-size: cover;
}
.paperIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
  background: url("../../assets/paperIcon.png") no-repeat;
  background-size: cover;
}
.nullIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
}
</style>
